var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.status !== null)?_c('div',[_vm._l((_vm.statusGroups),function(statusGroup){return _c('div',{key:statusGroup.name,staticClass:"mb-12"},[_c('h4',[_vm._v(" "+_vm._s(_vm.getStatusGroupName(statusGroup.name))+" "),_c('span',{staticClass:"text-muted"},[_vm._v(" ("+_vm._s(_vm.getStatusGroupAddressCount(statusGroup))+" Gesamt) ")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',_vm._l((statusGroup.items),function(status){return _c('v-col',{key:statusGroup.name + '_' + status.name,attrs:{"cols":"12","lg":"6"}},[_c('BasicStatWidget',{attrs:{"value":status.n,"label":_vm.getStatusName(
                    statusGroup.name,
                    status.name.replace('status_', '')
                  ),"icon-type":_vm.getStatusIconStyle(
                    statusGroup.name,
                    status.name.replace('status_', '')
                  ),"icon-source":_vm.getStatusIconClass(
                    statusGroup.name,
                    status.name.replace('status_', '')
                  ),"is-loading":false,"is-error":false}})],1)}),1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('StatusBarChart',{attrs:{"status-group":statusGroup}})],1)],1)],1)}),_c('dashboard-status-product-widget',{staticClass:"mb-12"})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }