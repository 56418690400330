<template>
  <div>
    <!--begin::dashboard-->
    <status-widgets />
    <!--end::dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import StatusWidgets from "@/view/pages/dashboard/widgets/StatusWidgets";

export default {
  name: "dashboard",
  components: {
    StatusWidgets
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "tktVivax", subTitle: "Vertriebssteuerungstool" }
    ]);
  }
};
</script>
