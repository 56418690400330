<template>
  <div v-if="!loading">
    <h4>
      {{ statusGroupName }}
      <span class="text-muted">({{ statusGroupAddressCount }} Gesamt)</span>
    </h4>
    <v-row>
      <v-col cols="12" md="6">
        <v-row>
          <v-col
            v-for="(status, key) in statusAddressCounts"
            :key="key"
            cols="12"
            lg="6"
          >
            <BasicStatWidget
              :value="status"
              :label="getStatusName(key)"
              :icon-type="getStatusIconStyle(key)"
              :icon-source="getStatusIconClass(key)"
              :is-loading="false"
              :is-error="false"
            ></BasicStatWidget>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <StatusBarChart :status-group="statusGroupObject" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import i18nDiclinaService from "../../core/services/diclina_i18n";
import BasicStatWidget from "../content/widgets/stats/BasicStatWidget";
import StatusBarChart from "../pages/dashboard/charts/StatusBarChart";

export default {
  name: "DashboardStatusProductWidget",
  components: { StatusBarChart, BasicStatWidget },
  data() {
    return {
      statusAddressCounts: {
        open: 0,
        opened: 0,
        rejected: 0,
        accepted: 0,
        active: 0
      },
      loading: false
    };
  },
  computed: {
    statusGroupKey() {
      return "product";
    },

    statusGroupAddressCount() {
      let sum = 0;
      for (let el in this.statusAddressCounts) {
        if (this.statusAddressCounts.hasOwnProperty(el)) {
          sum += this.statusAddressCounts[el];
        }
      }
      return sum;
    },

    statusGroupName() {
      return i18nDiclinaService.getDiclinaStatusGroupName(this.statusGroupKey);
    },

    statusGroupObject() {
      let items = [];

      for (const [key, value] of Object.entries(this.statusAddressCounts)) {
        items.push({ name: key, n: value });
      }

      return {
        name: "product",
        items
      };
    },
    ...mapGetters(["status"])
  },
  methods: {
    getStatusName(status) {
      return i18nDiclinaService.getDiclinaStatusName(
        this.statusGroupKey,
        status
      );
    },

    getStatusStyle(status) {
      return i18nDiclinaService.getDiclinaStatusStyle(
        this.statusGroupKey,
        status
      );
    },

    getStatusIconStyle(status) {
      return this.getStatusStyle(status).color || "";
    },

    getStatusIconClass(status) {
      return this.getStatusStyle(status).iconCssClass || "fas fa-question";
    }
  },
  async created() {
    this.loading = true;
    const addresses = await this.$store.getters.addresses();

    for (let i = 0; i < addresses.length; i++) {
      const currAddress = addresses[i];

      if (currAddress.customers) {
        for (let j = 0; j < currAddress.customers.length; j++) {
          const currCustomer = currAddress.customers[j];

          this.statusAddressCounts[currCustomer.status.product]++;
        }
      } else {
        this.statusAddressCounts.open++;
      }
    }

    this.loading = false;
  }
};
</script>

<style scoped></style>
